import { useEffect, useState } from 'react';

import { Invoice, SidebarNav } from '../../components';
import {
  /*useBillableEvents,*/
  useCustomer,
  useTokenAuth
} from '../../hooks';

import postJSON from '../../utils/postJSON';

import {
  ADD_LINE,
  ALREADY_PAID,
  CREATE,
  CREATE_INVOICE_ERROR,
  DAD,
  DEFAULT_RATE,
  GENERATE_INVOICE_URL,
  HOSTING,
  INVOICE,
  INVOICE_COMMENT,
  INVOICE_INSTRUCTIONS,
  INVOICE_NOTE,
  INVOICE_URL,
  MOM,
  PARENTS,
  PAYABLE_TO,
  SHOW_ADJUSTMENTS,
  VISITS
} from '../../constants';

import '../../dashboard.css';

const PARENT_TYPE = {
  MOM,
  DAD,
  PARENTS
};

const BOOKING_TYPE = {
  HOSTING,
  VISITS
};

const today = new Date();
const tomorrow = new Date(today);

tomorrow.setDate(tomorrow.getDate() + 1);

export default ({ slug }) => {
  const { email, token } = useTokenAuth();
  const { customer } = useCustomer({ slug });
  // const { invoiceLines: billableInvoiceLines } = useBillableEvents(customer);

  const [invoiceLines, setInvoiceLines] = useState([]);
  const [comment, setComment] = useState();
  const [parentType, setParentType] = useState(PARENT_TYPE.PARENTS);
  const [bookingType, setBookingType] = useState(BOOKING_TYPE.VISITS);
  const [dueDate, setDueDate] = useState(tomorrow);
  const [invoiceNumber, setInvoiceNumber] = useState(1);
  const [pendingParentType, setPendingParentType] = useState();
  const [pendingBookingType, setPendingBookingType] = useState();
  const [pendingDueDate, setPendingDueDate] = useState();
  const [pendingLine, setPendingLine] = useState();
  const [pendingDate, setPendingDate] = useState(today);
  const [pendingQuantity, setPendingQuantity] = useState(1);
  const [pendingPrice, setPendingPrice] = useState();
  const [pendingInvoiceNumber, setPendingInvoiceNumber] = useState();
  const [isSidebarNavOpen, setIsSidebarNavOpen] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [showAdjustments, setShowAdjustments] = useState(false);
  const [paid, setPaid] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (!comment) {
      const cachedComment = JSON.parse(
        sessionStorage.getItem(INVOICE_COMMENT) || '{}'
      )[customer?.id];

      if (cachedComment) {
        setComment(cachedComment);
      } else if (disabled) {
        fetchGeneratedComment();
      }
    }

    setDisabled(false);

    if (customer?.id) {
      setPendingPrice(customer.rate);
      setDiscount(DEFAULT_RATE - customer.rate);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const fetchGeneratedComment = async () => {
    setDisabled(true);

    const result = await postJSON(GENERATE_INVOICE_URL, {
      token,
      username: email,
      customerId: customer?.id
    });

    setDisabled(false);

    if (result?.comment) {
      setComment(result.comment.trim());
    }
  };

  const createInvoice = () => ({
    recipientName: customer.ownerName,
    recipientAddress: customer.address,
    catName: customer.catName,
    parentType,
    bookingType,
    dueDate,
    invoiceNumber,
    invoiceLines: invoiceLines.map(invoiceLine => ({
      id: invoiceLine.id,
      date: invoiceLine.date,
      quantity: invoiceLine.quantity << 0,
      rate: customer.rate
    })),
    payableTo: PAYABLE_TO,
    comment,
    adjustments: showAdjustments ? -discount : 0,
    paid
  });

  const onClickSaveInvoice = async () => {
    const invoice = createInvoice();

    const { invoiceId } = await postJSON(INVOICE_URL, {
      customerId: customer.id,
      invoice,
      token,
      username: email
    });

    if (invoiceId) {
      sessionStorage.clear();

      const invoicePath = `/invoice/${customer.slug}/${invoiceId}`;

      window.location.href = invoicePath;
    } else {
      alert(CREATE_INVOICE_ERROR);
    }
  };

  const onChangeComment = ({ target: { value } }) => {
    setComment(value);

    sessionStorage.setItem(
      INVOICE_COMMENT,
      JSON.stringify({
        [customer.id]: value
      })
    );
  };

  const onClickAddLine = () =>
    setInvoiceLines([
      ...invoiceLines,

      {
        id: invoiceLines.length,
        date: today.toLocaleDateString(),
        quantity: 1,
        price: customer.rate
      }
    ]);

  const onClickRemoveLine = lineIndex => () => {
    const updatedLines = [...invoiceLines];

    updatedLines.splice(lineIndex, 1);
    setInvoiceLines(updatedLines);
  };

  const onClickLine =
    lineIndex =>
    ({ target: { className } }) => {
      if (pendingLine || className?.match(/delete-button/)) return;

      setPendingDate(new Date(invoiceLines[lineIndex].date));
      setPendingQuantity(invoiceLines[lineIndex].quantity);
      setPendingPrice(invoiceLines[lineIndex].price);
      setPendingLine(lineIndex);
    };

  const onClickUpdateLine = lineIndex => () => {
    onClickRemoveLine(lineIndex)();

    const updatedLines = [...invoiceLines];

    updatedLines.splice(lineIndex, 1);

    setInvoiceLines([
      ...updatedLines,

      {
        id: invoiceLines.length - 1,
        date: (pendingDate || today).toLocaleDateString(),
        quantity: pendingQuantity << 0,
        price: pendingPrice
      }
    ]);

    setPendingLine(null);
    setPendingDate(null);
    setPendingQuantity(null);
    setPendingPrice(null);
  };

  const onClickDueDate = () => !pendingDueDate && setPendingDueDate(tomorrow);

  const onClickInvoiceNumber = () =>
    setPendingInvoiceNumber(customer.invoices.length + 1);

  const onClickBookingType = () =>
    !pendingBookingType && setPendingBookingType(BOOKING_TYPE.VISITS);

  const onClickParentType = () =>
    !pendingParentType && setPendingParentType(PARENT_TYPE.PARENTS);

  const onClickReload = () => {
    sessionStorage.clear();
    fetchGeneratedComment();
  };

  const onChangePendingDueDate = date => setPendingDueDate(date);

  const onChangePendingDate = date => setPendingDate(date);

  const onChangePendingInvoiceNumber = number =>
    setPendingInvoiceNumber(number);

  const onChangePendingQuantity = quantity => {
    setPendingQuantity(quantity);
    setPendingPrice(quantity * customer.rate);
  };

  const onChangeDueDate = () => {
    setDueDate(pendingDueDate);
    setPendingDueDate(null);
  };

  const onChangeInvoiceNumber = () => {
    setInvoiceNumber(pendingInvoiceNumber);
    setPendingInvoiceNumber(null);
  };

  const onChangeBookingType = ({ target: { value } }) => {
    setBookingType(value);
    setPendingBookingType(null);

    for (const line of invoiceLines) {
      line.price = line.quantity * customer.rate;
    }
  };

  const onChangeParentType = ({ target: { value } }) => {
    setParentType(value);
    setPendingParentType(null);
  };

  const onClickMenu = () => {
    setIsSidebarNavOpen(!isSidebarNavOpen);
  };

  const onClickAdjustments = () => setShowAdjustments(!showAdjustments);

  const onClickPaid = () => setPaid(!paid);

  if (!customer?.id) {
    return <div />;
  }

  const lines = [/*...billableInvoiceLines,*/ ...invoiceLines];

  return (
    <section className="sidebar-section">
      <SidebarNav isOpen={isSidebarNavOpen} setIsOpen={setIsSidebarNavOpen} />
      <div className="customers-wrapper invoice-builder">
        <h2>
          <i onClick={onClickMenu}>=</i>
          {INVOICE} for @{slug}
        </h2>
        <p className="panel" style={{ margin: '1rem 0' }}>
          {INVOICE_INSTRUCTIONS}
        </p>
        <Invoice
          customer={customer}
          parentType={parentType}
          bookingType={bookingType}
          dueDate={dueDate}
          invoiceNumber={invoiceNumber}
          invoiceLines={lines}
          comment={comment}
          isEditable={true}
          adjustments={(showAdjustments && -discount) << 0}
          paid={paid}
          Editor={{
            pendingParentType,
            pendingBookingType,
            pendingDueDate,
            pendingLine,
            pendingDate,
            pendingQuantity,
            pendingPrice,
            pendingInvoiceNumber,
            onClickLine,
            onClickUpdateLine,
            onClickRemoveLine,
            onClickDueDate,
            onClickBookingType,
            onClickParentType,
            onClickInvoiceNumber,
            onChangePendingDueDate,
            onChangePendingDate,
            onChangePendingQuantity,
            onChangePendingInvoiceNumber,
            onChangeDueDate,
            onChangeBookingType,
            onChangeParentType,
            onChangeInvoiceNumber
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end'
            }}
          >
            <button
              style={{
                display: 'block',
                margin: '1rem 0 0',
                padding: '.5rem 1rem'
              }}
              onClick={onClickAddLine}
            >
              + {ADD_LINE}
            </button>
          </div>
        </Invoice>
        <div className="dashboard-row">
          <div>
            <input
              type="checkbox"
              onChange={onClickAdjustments}
              value={showAdjustments}
            />
            &nbsp;
            <span>{SHOW_ADJUSTMENTS}</span>
          </div>
          <div>
            <input type="checkbox" onChange={onClickPaid} value={paid} />
            &nbsp;
            <span>{ALREADY_PAID}</span>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <p className="dashboard-heading" style={{ margin: 0 }}>{INVOICE_NOTE}</p>
          <button className="reload" onClick={onClickReload}>
            <span>↺</span>
            <span>Generate</span>
          </button>
        </div>
        <textarea disabled={disabled} defaultValue={comment} onChange={onChangeComment} />
        <button onClick={onClickSaveInvoice}>{CREATE}</button>
      </div>
    </section>
  );
};
