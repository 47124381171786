import { useEffect, useState } from 'react';

import useTokenAuth from '../useTokenAuth';

import postJSON from '../../utils/postJSON';

import { CUSTOMERS_URL } from '../../constants';

export default () => {
  const { email, token, deleteSession } = useTokenAuth();

  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchCustomers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCustomers = async () => {
    const result = await postJSON(CUSTOMERS_URL, {
      token,
      username: email
    });

    if (result?.customers) {
      setCustomers(
        result.customers.map(
          ({ id, ownerName, catName, invoices, notes, photo, slug, lists }) => ({
            id,
            ownerName,
            catName,
            invoices,
            notes,
            photo,
            slug,
            lists
          })
        )
      );

      setIsLoading(false);

      return;
    }

    deleteSession();

    window.location.href = '/login';
  };

  return {
    customers,
    setCustomers,
    isLoading,
    setIsLoading,
    fetchCustomers
  };
};
