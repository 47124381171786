const {
  REACT_APP_API_URL,
  REACT_APP_EXACTCHANGE_URL
} = process.env;

// Forms, Headings, Copy

const ABOUT = 'About';
const HOSTING = 'Hosting';
const VISITS = 'In-Home Visits';
const FAQ_HEADING = 'FAQ';
const EDIT_PROFILE = 'Edit Profile';
const NAME = 'name';
const EMAIL_ADDRESS = 'email address';
const PHONE_NUMBER = 'phone number';
const ADDRESS = 'address';
const PICKUP_ADDRESS = 'pick-up address';
const DROPOFF_ADDRESS = 'drop-off address';
const CAT = 'cat';
const OWNERS_NAME = "Owner's name";
const CATS_NAME = "Cat's name";
const ENTER_OWNERS_NAME = "Enter owner's name";
const ENTER_CATS_NAME = "Enter cat's name";
const ENTER_VALID_EMAIL = 'Enter a valid email address';
const ENTER_VALID_ADDRESS = 'Enter a valid street address';
const ENTER_VALID_PHONE = 'Enter a valid phone number';
const ENTER_WHOLE_NUMBER = 'Enter a whole number';
const YOUR_NAME = 'Your name';
const YOUR_ADDRESS = 'Your name';
const YOUR_CATS_NAME = "Your cat's name";
const ACTIONS = 'Actions';
const SUMMARY = 'Summary';
const CALENDAR = 'Calendar';
const CUSTOMER = 'Customer';
const CUSTOMERS = 'Customers';
const CONTACT = 'Contact';
const EVENT = 'Event';
const CREATE = 'Create';
const THANK_YOU = 'Thank You!';
const INVALID_LABEL = 'Missing or invalid:';
const NAME_LABEL = 'Name:';
const FROM_LABEL = 'From:';
const TO_LABEL = 'To:';
const NAME_LABEL_FULL = `${YOUR_NAME}:`;
const CAT_NAME_LABEL_FULL = `${YOUR_CATS_NAME}:`;
const EMAIL_LABEL = 'Email:';
const EMAIL_LABEL_FULL = 'Your email address:';
const PHONE_LABEL = 'Phone:';
const PHONE_LABEL_FULL = 'Your phone number:';
const ADDRESS_LABEL = 'Street address:';
const ADDRESS_LABEL_FULL = 'Your SF street address:';
const VET_LABEL = 'Vet contact:';
const VET_LABEL_FULL = "Your vet's contact info:";
const VET_CONTACT_INFO = 'Veterinary contact information';
const ADDITIONAL_INFO_LABEL = 'Additional info:';
const CHECK_IN_TIME = 'Check in time';
const CHECK_IN_LABEL = 'Check in:';
const CHECK_OUT_TIME = 'Check out time';
const CHECK_OUT_LABEL = 'Check out:';
const COMMENTS_LABEL = 'Other comments:';
const IN_HOME_RATE_LABEL = 'In-home rate:';
const EXTRAS_LABEL = 'Extras:';
const TIME_OF_DAY_LABEL = 'Time of day:';
const KITTY_QUIRKS_LABEL = 'Kitty quirks:';
const KITTY_NEEDS_A_RIDE = 'Kitty needs a ride?';
const KITTY_NEEDS_A_RIDE_FULL =
  'Does your kitty need a ride? (free of charge!)';
const PROVIDE_CATNIP = 'Provide catnip?';
const PROVIDE_CATNIP_FULL = 'Provide catnip (free of charge!)';
const PROVIDE_TEMPTATIONS = 'Provide Temptations treats?';
const PROVIDE_TEMPTATIONS_FULL = 'Provide Temptations treats (free of charge!)';
const PROVIDE_TREATS = 'Provide freeze dried minnows/shrimp treats?';
const PROVIDE_TREATS_FULL = 'Provide freeze dried treats (free of charge!)';
const ADMINISTER_MEDICATION = 'Administer medication (+$10)';
const MORNING_VISIT = 'Morning visits';
const AFTERNOON_VISIT = 'Afternoon visits';
const EVENING_VISIT = 'Evening visits';
const MORNING_VISITS = 'Morning visit(s)?';
const AFTERNOON_VISITS = 'Afternoon visit(s)?';
const EVENING_VISITS = 'Evening visit(s)?';
const WATER_PLANTS = 'Water the plant(s)?';
const WATER_PLANTS_FULL = 'Water plants (free of charge!)';
const CONFIRM = 'Confirm';
const EDIT = 'Go back and make changes';
const BOOK_IT = 'Book it!';
const SF_ADDRESSES_ONLY = 'SF addresses only please!';
const CAT_DESCRIPTION = 'Briefly describe what I should know about your cat';
const ENTRY_INSTRUCTIONS = 'Entry instructions';
const ENTRY_INSTRUCTIONS_LABEL = `${ENTRY_INSTRUCTIONS}:`;
const KEY_DROP_OFF_LABEL = 'Key drop off date:';
const KEY_PICK_UP_LABEL = 'Key pick up date:';
const KEY_PICK_UP_TIME_LABEL = 'Key pick up time:';
const PAY_WITH_VENMO_LABEL = "If you'd like to pay with Venmo:";
const NONE_GIVEN = 'None given';
const LOCK_BOX = 'Lock box';
const KEY_STASHED = 'Key is stashed';
const KEY_PICK_UP = 'Key pick up';
const KEY_DROP_OFF = 'Key drop off';
const KEY_IN_LOCK_BOX = 'Key will be left in a lockbox';
const KEY_IS_STASHED = 'Key will be stashed somewhere';
const KEY_IS_DROPPED_OFF = 'Key will be dropped off';
const KEY_LOCATION = 'Key location';
const LOCK_BOX_CODE = 'Lock box code';
const KEY_DROP_OFF_LOCATION = 'Key drop off location';
const KEY_DROP_OFF_ADDRESS = '35 Belvedere St.';
const KEY_NEEDS_PICK_UP = 'Key needs to be picked up';
const DROP_OFF_TIME = 'Drop-off time is at 12:00 PM';
const KEY_PICK_UP_TIME = 'Key pick up time';
const DUE_BY = 'Due by';
const ADD_LINE = 'Add Line';
const CITY = 'San Francisco';
const MOM = 'mom';
const DAD = 'dad';
const PARENTS = 'parents';
const CUSTOMER_NOT_FOUND = 'Customer not found.';
const CUSTOMER_CREATED = 'Customer created.';
const HOSTING_DISCLAIMER = "Cats are very sensitive to new environments and my ultimate goal with Slow Blinks is to care for and comfort your cat while you're away. Hosting can be tough for most cats due to the change of surroundings, so I really only recommend it for special circumstances.";
const HOSTING_INTRO =
  "I have created a safe, fun and calm environment for cats in my home. Kitties can explore or they can hunker down on their own if that's more their speed. I have years of experience caring for kittens, special needs and senior cats. Hosting includes: Around the clock care, feedings, play time, lots of love and attention (if they allow it!).";
const VISITS_INTRO =
  "I am happy to accommodate any instructions unique to your cat. Generally, in-home visits include: Feedings, play time, litter box duties, waste removal, general cleaning and lots of love. ";
const INVOICE_INSTRUCTIONS =
  "Add invoice line items using the controls below. Don't forget to set the parent type, invoice number, and due date.";
const VENMO_USERNAME = '@Chelsea-Lee-195';
const PAGE_TITLE = 'Slow Blinks Cat Care | Chelsea Yasuko Lee';
const PAYABLE_TO = 'Chelsea Lee';
const PAYABLE_TO_STREET_ADDRESS = '35 Belvedere St. Apt. 5';
const PAYABLE_TO_CITY = 'San Francisco, CA 94117';
const PAYABLE_TO_PHONE = '(415) 691-9947';
const SLOW_BLINKS_EMAIL = 'chelsea@slowblinkscatcare.com';
const SPECIFY_TIME = '- specify time';
const HOSTING_PRICE = '$105 per day for full-time care.';
const VISITS_PRICE = '$35 per visit.';
const COMMON_VISITS = 'A morning and evening visit each day is most common.';
const OTHER = 'Other';
const INVOICE = 'Invoice';
const INVOICES = 'Invoices';
const NO_INVOICES = 'No invoices';
const CREATE_INVOICE = 'Create Invoice';
const CREATE_INVOICE_ERROR = 'Error creating invoice.';
const CREATE_LIST = 'Add Task List';
const ADD_CUSTOMER = 'Add Customer';
const SUBMITTED_ON = 'Submitted on';
const ADJUSTMENTS = 'Adjustments';
const SHOW_ADJUSTMENTS = `Show ${ADJUSTMENTS.toLowerCase()}`;
const ALREADY_PAID = 'Already paid';
const INVOICE_NOTE = 'Invoice comment';
const ACCEPTS = 'Accepts';
const NOTES = 'Notes, comments, or special instructions';
const LISTS = 'Task lists';
const LIST_NAME = 'List name';
const TASKS = 'Tasks';

// Controls

const X = '×';
const LOGIN = 'Login';
const CLOSE = 'Close';
const EXIT = 'Exit';
const ARE_YOU_SURE = 'Are you sure?';
const NOTE = 'Notes';
const DATE = 'Date';
const QUANTITY = 'Qty';
const PRICE = 'Price';
const RATE = 'Rate';
const DELETE = 'Delete';
const TOTAL = 'Total';

const SAVE = 'Save';
const VIEW = 'View';
const TYPE = 'Type';
const START = 'Start';
const END = 'End';
const YES = 'Yes';
const NO = 'No';

// Data

const AM = 'AM';
const PM = 'PM';
const NA = 'N/A';
const NOON = '12:00 PM';
const TIME = 'Time';
const MONTH = 'month';
const WEEK = 'week';
const DAY = 'day';
const CURRENCY = 'currency';
const USD = 'USD';
const USER = 'USER';
const INVOICE_COMMENT = 'INVOICE_COMMENT';
const LANGUAGE = 'en';
const LANGUAGE_FULL = 'en-US';
const THREE_DAYS_MS = 259200000;
const DEFAULT_RATE = 35;
const DEFAULT_HOSTING_RATE = 105;
const SIDERIAL = 12;
const INDEX_BEFORE_LAST = -2;

// Requests, Errors

const EXACTCHANGE_URL = `${REACT_APP_EXACTCHANGE_URL}`;
const BOOK_HOSTING_URL = `${REACT_APP_API_URL}/book-hosting`;
const BOOK_VISIT_URL = `${REACT_APP_API_URL}/book-visit`;
const LOGIN_URL = `${REACT_APP_API_URL}/login`;
const CUSTOMER_URL = `${REACT_APP_API_URL}/customer`;
const CUSTOMERS_URL = `${REACT_APP_API_URL}/customers`;
const CALENDAR_URL = `${REACT_APP_API_URL}/calendar`;
const CREATE_URL = `${REACT_APP_API_URL}/create`;
const INVOICE_URL = `${REACT_APP_API_URL}/invoice`;
const EVENT_URL = `${REACT_APP_API_URL}/event`;
const REMOVE_URL = `${REACT_APP_API_URL}/remove`;
const CHANGE_URL = `${REACT_APP_API_URL}/change`;
const UPDATE_URL = `${REACT_APP_API_URL}/update`;
const GENERATE_INVOICE_URL = `${REACT_APP_API_URL}/llm/invoice`;
const REQUEST_ERROR = '⚠️ There was a problem with the request.';
const REQUEST_SUCCESS =
  '🐱 Thank you, your request was received. Please check your email for further communications. 🐱';

// HTML

const SELECTORS = {
  name: '#name',
  email: '#email',
  phone: '#phone',
  address: '#address',
  pickupAddress: '#pick-up-address',
  dropOffAddress: '#drop-off-address',
  validity: ':valid'
};

// Emoji

const CALENDAR_EMOJI = '📅';
const CLOCK_EMOJI = '🕑';

// Exports

export {
  ABOUT,
  ACCEPTS,
  ACTIONS,
  ADDITIONAL_INFO_LABEL,
  ADDRESS,
  ADDRESS_LABEL,
  ADDRESS_LABEL_FULL,
  ADD_CUSTOMER,
  ADD_LINE,
  ADJUSTMENTS,
  ADMINISTER_MEDICATION,
  AFTERNOON_VISIT,
  AFTERNOON_VISITS,
  ALREADY_PAID,
  AM,
  ARE_YOU_SURE,
  BOOK_HOSTING_URL,
  BOOK_IT,
  BOOK_VISIT_URL,
  CALENDAR,
  CALENDAR_EMOJI,
  CALENDAR_URL,
  CAT,
  CATS_NAME,
  CAT_DESCRIPTION,
  CAT_NAME_LABEL_FULL,
  CHANGE_URL,
  CHECK_IN_LABEL,
  CHECK_IN_TIME,
  CHECK_OUT_LABEL,
  CHECK_OUT_TIME,
  CITY,
  CLOCK_EMOJI,
  CLOSE,
  COMMENTS_LABEL,
  COMMON_VISITS,
  CONFIRM,
  CONTACT,
  CREATE,
  CREATE_INVOICE,
  CREATE_INVOICE_ERROR,
  CREATE_LIST,
  CREATE_URL,
  CURRENCY,
  CUSTOMER,
  CUSTOMERS,
  CUSTOMERS_URL,
  CUSTOMER_CREATED,
  CUSTOMER_NOT_FOUND,
  CUSTOMER_URL,
  DAD,
  DATE,
  DAY,
  DEFAULT_RATE,
  DEFAULT_HOSTING_RATE,
  DELETE,
  DROPOFF_ADDRESS,
  DROP_OFF_TIME,
  DUE_BY,
  EDIT,
  EDIT_PROFILE,
  EMAIL_ADDRESS,
  EMAIL_LABEL,
  EMAIL_LABEL_FULL,
  END,
  ENTER_CATS_NAME,
  ENTER_OWNERS_NAME,
  ENTER_VALID_ADDRESS,
  ENTER_VALID_EMAIL,
  ENTER_VALID_PHONE,
  ENTER_WHOLE_NUMBER,
  ENTRY_INSTRUCTIONS,
  ENTRY_INSTRUCTIONS_LABEL,
  EVENING_VISIT,
  EVENING_VISITS,
  EVENT,
  EVENT_URL,
  EXACTCHANGE_URL,
  EXIT,
  EXTRAS_LABEL,
  FAQ_HEADING,
  FROM_LABEL,
  GENERATE_INVOICE_URL,
  HOSTING,
  HOSTING_DISCLAIMER,
  HOSTING_INTRO,
  HOSTING_PRICE,
  INDEX_BEFORE_LAST,
  INVALID_LABEL,
  INVOICE,
  INVOICES,
  INVOICE_COMMENT,
  INVOICE_INSTRUCTIONS,
  INVOICE_NOTE,
  INVOICE_URL,
  IN_HOME_RATE_LABEL,
  KEY_DROP_OFF,
  KEY_DROP_OFF_ADDRESS,
  KEY_DROP_OFF_LABEL,
  KEY_DROP_OFF_LOCATION,
  KEY_IN_LOCK_BOX,
  KEY_IS_DROPPED_OFF,
  KEY_IS_STASHED,
  KEY_LOCATION,
  KEY_NEEDS_PICK_UP,
  KEY_PICK_UP,
  KEY_PICK_UP_LABEL,
  KEY_PICK_UP_TIME,
  KEY_PICK_UP_TIME_LABEL,
  KEY_STASHED,
  KITTY_NEEDS_A_RIDE,
  KITTY_NEEDS_A_RIDE_FULL,
  KITTY_QUIRKS_LABEL,
  LANGUAGE,
  LANGUAGE_FULL,
  LIST_NAME,
  LISTS,
  LOCK_BOX,
  LOCK_BOX_CODE,
  LOGIN,
  LOGIN_URL,
  MOM,
  MONTH,
  MORNING_VISIT,
  MORNING_VISITS,
  NA,
  NAME,
  NAME_LABEL,
  NAME_LABEL_FULL,
  NO,
  NONE_GIVEN,
  NOON,
  NOTE,
  NOTES,
  NO_INVOICES,
  OTHER,
  OWNERS_NAME,
  PAGE_TITLE,
  PARENTS,
  PAYABLE_TO,
  PAYABLE_TO_CITY,
  PAYABLE_TO_PHONE,
  PAYABLE_TO_STREET_ADDRESS,
  PAY_WITH_VENMO_LABEL,
  PHONE_LABEL,
  PHONE_LABEL_FULL,
  PHONE_NUMBER,
  PICKUP_ADDRESS,
  PM,
  PRICE,
  PROVIDE_CATNIP,
  PROVIDE_CATNIP_FULL,
  PROVIDE_TEMPTATIONS,
  PROVIDE_TEMPTATIONS_FULL,
  PROVIDE_TREATS,
  PROVIDE_TREATS_FULL,
  QUANTITY,
  RATE,
  REMOVE_URL,
  REQUEST_ERROR,
  REQUEST_SUCCESS,
  SAVE,
  SELECTORS,
  SF_ADDRESSES_ONLY,
  SHOW_ADJUSTMENTS,
  SIDERIAL,
  SLOW_BLINKS_EMAIL,
  SPECIFY_TIME,
  START,
  SUBMITTED_ON,
  SUMMARY,
  TASKS,
  THANK_YOU,
  THREE_DAYS_MS,
  TIME,
  TIME_OF_DAY_LABEL,
  TOTAL,
  TO_LABEL,
  TYPE,
  UPDATE_URL,
  USD,
  USER,
  VENMO_USERNAME,
  VET_CONTACT_INFO,
  VET_LABEL,
  VET_LABEL_FULL,
  VIEW,
  VISITS,
  VISITS_INTRO,
  VISITS_PRICE,
  WATER_PLANTS,
  WATER_PLANTS_FULL,
  WEEK,
  X,
  YES,
  YOUR_ADDRESS,
  YOUR_CATS_NAME,
  YOUR_NAME
};
